.container {
  padding: 0 2rem;
}

.header {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  justify-items: end;
}

.main {
  margin: 2rem auto 0;
  min-height: calc(100vh - 4rem);
  padding: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 4rem;
  }
  > img {
    height: auto;
    width: 200px;
  }
}

.mainInput {
  height: 2.4rem;
  width: 100%;
  padding: 1rem;
  margin-top: .5rem;
  border: solid 1px #ddd;
  border-radius: 2px;
  background-color: white;
  &:disabled {
    background-color: #eee;
  }
}
.select {
  height: 2.4rem;
  width: 100%;
  padding: 0 1rem;
  margin-top: .5rem;
  border: solid 1px #ddd;
  border-radius: 2px;
  option {
    color: black;
  }
}

.reactSelect {
  margin-top: .5rem;
}

.content {
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  @media (min-width: 1200px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (min-width: 1600px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.controls {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr .5fr;
  }
}

.title a {
  font-size: 1rem;
  color: #0070f3;
  text-decoration: none;
  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}

.description {
  margin: 4rem 0;
  line-height: 1.5;
  font-size: 1.5rem;
}

.card {
  padding: 1rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  background-color: #fff;
  h2 {
    margin: 0 0 .5rem 0;
    font-size: 1.5rem;
  }
  pre {
    word-break: break-all;
    white-space: break-spaces;
  }
}

.cardHeading {
  display: grid;
  grid-template-columns: 1fr 1fr;
  *:nth-child(2) {
    justify-self: right;
  }
}
